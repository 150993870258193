<template>
  <v-container fluid class="main-layout">
    <v-row no-gutters>
      <v-col lg="6" v-show="$vuetify.breakpoint.mdAndUp">
        <background-image />
      </v-col>
      <v-col col="6" class="form-container">
        <form class="md-layout complete-profile">
          <div style="width: 70%">
            <v-container>
              <v-row justify="center">
                <v-col justify="center" cols="12">
                  <a href="#/" class="img-style">
                    <img :src="logoImg" width="180px" />
                  </a>
                  <div class="title">Just one more step to go</div>
                </v-col>
              </v-row>
            </v-container>

            <md-card-content class="data-container">
              <div class="md-layout md-gutter">
                <v-col cols="12" md="12" lg="12">
                  <div class="md-layout-item md-small-size-100">
                    <md-field>
                      <md-input
                        name="email"
                        id="email"
                        class="input-data"
                        v-model="model.email"
                        :disabled="true"
                      />
                    </md-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClass('firstName')">
                      <md-input
                        name="first-name"
                        id="first-name"
                        class="input-data"
                        v-model="model.firstName"
                        placeholder="First Name"
                      />
                      <span class="md-error" v-if="firstNameValidationError">{{
                        firstNameValidationError
                      }}</span>
                    </md-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClass('lastName')">
                      <md-input
                        name="last-name"
                        id="last-name"
                        class="input-data"
                        v-model="model.lastName"
                        placeholder="Last Name"
                      />
                      <span class="md-error" v-if="lastNameValidationError">{{
                        lastNameValidationError
                      }}</span>
                    </md-field>
                  </div>
                </v-col>

                <v-col cols="12" md="12" lg="12">
                  <div class="md-layout-item md-small-size-100">
                    <button
                      style="display: none"
                      id="dummy-button-to-unfocus-dropdown-field"
                    />
                    <md-field
                      id="organization-registry"
                      class="field-margin"
                      :class="getValidationClass('organizationRegistry')"
                    >
                      <md-select
                        v-model="model.organizationRegistry"
                        name="organization-registry"
                        class="input input-width"
                        placeholder="Choose Your Business Name"
                      >
                        <v-text-field
                          class="search-width"
                          label="Search"
                          v-on:input="debounceSearch"
                          name="search"
                          v-model="searchName"
                          clearable
                        />
                        <global-registry-dropdown
                          :globalRegistryList="globalRegistryList"
                          :searchName="searchName"
                          @click="handleClick"
                          @fetch-more="fetchMore"
                        />
                      </md-select>
                      <span
                        class="md-error"
                        v-if="organizationRegistryValidationError"
                        >{{ organizationRegistryValidationError }}</span
                      >
                    </md-field>
                  </div>
                  <div class="select-account-span-label">
                    <organization-request
                      class="account-request-container"
                      :active="organizationRequesting"
                      @close="organizationRequesting = false"
                    />
                  </div>
                </v-col>

                <v-col cols="12">
                  <div class="terms-style">
                    <v-checkbox
                      v-model="model.terms"
                      :error-messages="checkboxErrors"
                      required
                      @change="$v.model.terms.$touch()"
                      @blur="$v.model.terms.$touch()"
                      color="#473068"
                    >
                      <template v-slot:label>
                        <span id="checkboxLabel">
                          I accept the{{ " " }}
                          <a
                            href="#"
                            @click.stop="openTerms"
                            class="login-link"
                            target="_blank"
                            >terms and conditions</a
                          >,{{ " " }}
                          <a
                            href="#"
                            @click.stop="openCodeOfConduct"
                            class="login-link"
                            target="_blank"
                            >code of conduct</a
                          >{{ " " }}and{{ " " }}
                          <a
                            href="#"
                            @click.stop="openPrivacyPolicy"
                            class="login-link"
                            target="_blank"
                            >privacy policy</a
                          >.
                        </span>
                      </template>
                    </v-checkbox>
                  </div>
                </v-col>
              </div>
            </md-card-content>
            <div style="margin-bottom: 26px">
              <md-button
                @click="save"
                class="wide-button save-btn"
                id="register"
                >Save
              </md-button>
              <md-button @click="backToLogin" class="wide-button cancel-btn"
                >Cancel
              </md-button>
            </div>
          </div>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from "../../auth";
import restAdapter from "../../restAdapter";
import defaultImage from "../../assets/person.png";
import notification from "../../notification";
import * as conf from "../../conf.yml";
import { maxLength, required } from "vuelidate/lib/validators";
import validationMixin from "../../validation/validation_mixin";
import BackgroundImage from "../../assets/background-images/BackgroundImage.vue";
import debounce from "lodash/debounce";
import InfiniteLoading from "vue-infinite-loading";
import GlobalRegistryDropdown from "./GlobalRegistryDropdown.vue";

/* eslint-disable */
export default {
  name: "CompleteProfile",
  mixins: [validationMixin],
  components: { BackgroundImage, InfiniteLoading, GlobalRegistryDropdown },

  data() {
    return {
      baseUrl: "",
      showDialog: false,
      organizationRequesting: false,
      logoImg: require(`../../assets/seamless-source-banner.png`),
      model: {
        email: null,
        firstName: "",
        lastName: "",
        organizationRegistry: null,
        imageUrl: defaultImage,
        terms: false,
        avatar: null,
      },
      globalRegistryList: [],
      searchName: "",
      currentPage: 1,
    };
  },
  validations: {
    model: {
      firstName: {
        required,
        maxLength: maxLength(150),
      },
      lastName: {
        required,
        maxLength: maxLength(150),
      },
      organizationRegistry: {
        required,
      },
      terms: {
        checked: (value) => value === true,
      },
    },
  },
  computed: {
    firstNameValidationError() {
      if (!this.$v.model.firstName.required) {
        return "First name is required";
      }

      if (!this.$v.model.firstName.maxLength) {
        return "First name should less than 150 characters";
      }

      return null;
    },
    lastNameValidationError() {
      if (!this.$v.model.lastName.required) {
        return "Last name is required";
      }

      if (!this.$v.model.lastName.maxLength) {
        return "Last name should less than 150 characters";
      }

      return null;
    },
    organizationRegistryValidationError() {
      if (!this.$v.model.organizationRegistry.required) {
        return "Business name is required";
      }
      return null;
    },
    termsValidationError() {
      if (!this.$v.model.terms.checked && this.$v.model.terms.$dirty) {
        return "Please accept the terms and conditions";
      }

      return null;
    },
    checkboxErrors() {
      const errors = [];
      if (!this.$v.model.terms.$dirty) return errors;
      !this.$v.model.terms.checked &&
        this.save &&
        errors.push("You must accept the terms and conditions");
      return errors;
    },
  },
  methods: {
    openTerms() {
      window.open("https://seamlesssource.com/code-of-conduct-2", "_blank");
    },
    openPrivacyPolicy() {
      window.open("https://seamlesssource.com/privacy-policy", "_blank");
    },
    openCodeOfConduct() {
      window.open("https://seamlesssource.com/code-of-conduct", "_blank");
    },
    getSocialAccountDetails() {
      const socialLoginResponse = JSON.parse(
        window.localStorage.getItem("socialLoginResponse")
      );
      const provider = JSON.parse(window.localStorage.getItem("provider"));
      auth
        .extractSocialDetails(provider, socialLoginResponse)
        .then((response) => {
          if (response.data.already_registered) {
            this.$router.push(this.$route.query.redirect || "/app");
            window.location.reload(true);
          } else {
            const user = response.data.user;
            this.model.firstName = user.first_name;
            this.model.lastName = user.last_name;
            this.model.email = user.email;
          }
        });
    },

    openOrganizationRequest() {
      this.organizationRequesting = true;
    },
    handleClick() {
      document.getElementById("dummy-button-to-unfocus-dropdown-field").click();
      this.openOrganizationRequest();
    },
    debounceSearch: debounce(function () {
      this.getGlobalRegistryList();
    }, 600),

    getGlobalRegistryList() {
      this.globalRegistryList = [];
      this.currentPage = 1;
      let url = "/api/organization_registry";
      url +=
        "?page=" +
        this.currentPage +
        "&name=" +
        (this.searchName ? this.searchName : "");
      restAdapter.get(url).then((response) => {
        this.globalRegistryList = response.data.data;
      });
    },

    fetchMore($state) {
      let url = "/api/organization_registry";
      url +=
        "?page=" +
        (this.currentPage + 1) +
        "&name=" +
        (this.searchName ? this.searchName : "");
      restAdapter.get(url).then((response) => {
        if (response.data.data.length) {
          this.globalRegistryList = this.globalRegistryList.concat(
            response.data.data
          );
          this.currentPage++;
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },

    async save() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const user = {
        ...JSON.parse(window.localStorage.getItem("socialLoginResponse")),
        first_name: this.model.firstName,
        last_name: this.model.lastName,
        organization_registry_id: this.model.organizationRegistry,
        terms_and_conditions: this.model.terms,
      };

      restAdapter
        .post(
          "/api/social/" + JSON.parse(window.localStorage.getItem("provider")),
          user
        )
        .then((response) => {
          window.localStorage.removeItem("provider");
          window.localStorage.removeItem("socialLoginResponse");
          auth.setAuth(response.data.success);
          notification.success("Welcome to " + conf.appName + "!");
          this.$router.push(this.$route.query.redirect || "/app");
          window.location.reload(true);
        })
        .catch((err) => {
          notification.errors(
            err.response.data.error ||
              "Something went wrong, please try again later."
          );
        });
    },
    backToLogin() {
      auth.user.token = null;
      window.localStorage.removeItem("app-user-token");
      window.localStorage.removeItem("provider");
      window.localStorage.removeItem("socialLoginResponse");
      this.$router.push({
        path: `/login`,
      });
    },
  },
  mounted() {
    this.getSocialAccountDetails();
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/CompleteProfile.scss";
</style>
<style>
.md-menu-content-container {
  overflow: unset !important;
  width: 100%;
}
</style>
